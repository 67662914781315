import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Preview from 'rev.sdk.js/Components/RichTextPreview';
import Image from '../../Components/Image';
import styled from 'styled-components';
import {Row, Text, Link, Divider} from '../../Widgets';
import {dateUtil} from '../../Utils';
import RelatedBlog from './RelatedBlog';
import ShareLink from '../../Components/ShareLink';
const appConfig = require('../../data.json');
const queryString = require('query-string');

export default function BlogDetail({location}) {
  const app = useContext(Context);
  const parsed = queryString.parse(location.search);
  const {id} = parsed;
  const [record, setRecord] = useState();

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        let resp = await app.actions.getBlog({
          query: {
            id,
          },
        });
        setRecord(resp);
      } catch (err) {
        console.log(err);
      }
      app.actions.setLoading(false);
    })();
  }, [app.actions, id]);

  if (!record) {
    return <ContentWrapper></ContentWrapper>;
  }

  return (
    <ContentWrapper maxWidth={appConfig.blogMaxWidth}>
      <Header>
        <h2>{record.title}</h2>
        <Text size="md" weight="500" style={{marginBottom: 10}}>
          {record.label[0]} {dateUtil.format(record.created, 'YYYY-MM-HH')}
        </Text>
        <Text size="sm" style={{marginBottom: 10}}>
          <Link to={`/articles`}>{appConfig.mapping.articles}</Link>
          {' > '}
          <Link to={`/articles/${record.label[0]}/`}>
            {record.label[0] || '-'}
          </Link>
          {' > '}
          {record.title}
        </Text>
        <Divider paddingSize="lg" />
      </Header>

      <Image
        src={record.image}
        style={{width: '100%', marginBottom: 40}}
        alt="blog detail image"
      />

      <Wrapper>
        <Preview content={record.content} readOnly />
      </Wrapper>
      <RelatedBlog label={record.label?.[0]} />
      <ShareLink item={record} />
    </ContentWrapper>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  & > h2 {
    font-size: ${appConfig.headingSize.lg}px;
    color: ${appConfig.colors.main};
    font-weight: 400;
    margin-bottom: 30px;
  }
`;

const Wrapper = styled.div`
  /* font-size: ${appConfig.fontSize.md}px; */
  margin-bottom: 50px;
  & p,
  blockquote,
  li,
  a {
    font-size: ${appConfig.fontSize.lg}px;
    line-height: 2;
  }
`;
