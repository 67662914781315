import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Context} from '../../AppContext';
import {Center} from '../../Components/Layout/ContentWrapper';
import Item from '../../Components/BlogItem';
import {Row, Col} from 'antd';
import {Divider} from '../../Widgets';
const appConfig = require('../../data.json');

export default function RelatedBlog(props) {
  const {label} = props;
  const app = useContext(Context);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const filters = [
          {
            label: {$not: {$regex: 'FAQ'}},
          },
          {
            label: {$not: {$regex: 'draft'}},
          },
          {
            label: '123',
          },
        ];

        if (label) {
          filters.push({
            label: {$regex: label},
          });
        }
        let resp = await app.actions.getBlogs({
          query: {
            $and: filters,
          },
          paging: {
            offset: 0,
            limit: 3,
          },
        });

        setRecords(resp.results);
      } catch (ex) {
        console.warn(ex);
      }
      setLoading(false);
    })();
  }, [app.actions, label]);

  return records.length > 0 ? (
    <Center>
      <Divider />
      <div style={{height: 20}} />
      <Title>
        <div className="title">相關文章推薦</div>
        <div className="subtitle">Recommend</div>
      </Title>
      <Row gutter={[20, 20]} style={{alignSelf: 'stretch', marginBottom: 50}}>
        {records.map((record) => (
          <Col xs={24} md={8} key={record.id}>
            <Item
              key={record.id}
              record={record}
              extraStyle={{}}
              type="portrait"
            />
          </Col>
        ))}
      </Row>
    </Center>
  ) : null;
}

const Title = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-bottom: 50px;

  & > .title {
    text-align: center;
    font-size: 22px;
    letter-spacing: 1px;
    color: ${appConfig.colors.second};
    margin: 10px 0 0 0;
  }

  & > .subtitle {
    text-align: center;
    font-size: 12px;
    color: #9b9b9c;
    border-bottom: 0.5px solid ${appConfig.colors.second};
    padding: 0 32px 10px 32px;
    margin-bottom: 2em;
  }
`;
